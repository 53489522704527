
import { onMounted } from "vue";
import { tableData, attributes } from "@/composition/store";
import { useRequestCalendarTable } from "@/composition/index";
import { Calendar, DatePicker } from "v-calendar";
import { useI18n } from "vue-i18n";

export default {
  components: {
    Calendar,
    DatePicker
  },
  setup() {
    const { t, locale } = useI18n();
    const { requestCalendarTable, requestCalendarMark } = useRequestCalendarTable();
    const move = async (page) => {
      await requestCalendarMark(page);
    };
    const onDayClick = (day) => {
      requestCalendarTable(day.range.start.toISOString(), day.range.end.toISOString());
    };
    const mimicISOString = (date) => {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      let startday;
      let endday;

      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2 && day === "9") {
        startday = "0" + day;
        endday = (parseInt(day) + 1).toString();
      } else if (day.length < 2) {
        startday = "0" + day;
        endday = "0" + (parseInt(day) + 1).toString();
      } else {
        startday = day;
        endday = (parseInt(day) + 1).toString();
      }
      return [
        [year, month, startday].join("-") + "T00:00:00.000Z",
        [year, month, endday].join("-") + "T00:00:00.000Z"
      ];
    };
    onMounted(async () => {
      attributes.value = [
        {
          key: "today",
          highlight: true,
          dates: new Date()
        }
      ];
      await requestCalendarTable(
        mimicISOString(new Date())[0],
        mimicISOString(new Date())[1]
      );
    });
    return { attributes, onDayClick, tableData, move, locale, t };
  }
};
