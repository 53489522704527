import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "table-responsive" }
const _hoisted_6 = { class: "table table-striped" }
const _hoisted_7 = {
  scope: "col",
  class: "text-nowrap"
}
const _hoisted_8 = {
  scope: "col",
  class: "d-none d-lg-table-cell"
}
const _hoisted_9 = { scope: "col" }
const _hoisted_10 = {
  scope: "col",
  class: "d-none d-lg-table-cell text-nowrap"
}
const _hoisted_11 = {
  scope: "col",
  class: "d-none d-md-table-cell text-nowrap"
}
const _hoisted_12 = { scope: "col" }
const _hoisted_13 = { class: "d-none d-lg-table-cell" }
const _hoisted_14 = { class: "d-none d-lg-table-cell" }
const _hoisted_15 = { class: "d-none d-md-table-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_Calendar, {
          "is-expanded": "",
          attributes: $setup.attributes,
          onDayclick: $setup.onDayClick,
          "onUpdate:fromPage": $setup.move,
          locale: $setup.locale
        }, null, 8, ["attributes", "onDayclick", "onUpdate:fromPage", "locale"])
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode("div", _hoisted_5, [
          _createVNode("table", _hoisted_6, [
            _createVNode("thead", null, [
              _createVNode("tr", null, [
                _createVNode("th", _hoisted_7, _toDisplayString($setup.t("calendar.index")), 1),
                _createVNode("th", _hoisted_8, _toDisplayString($setup.t("calendar.id")), 1),
                _createVNode("th", _hoisted_9, _toDisplayString($setup.t("calendar.time")), 1),
                _createVNode("th", _hoisted_10, _toDisplayString($setup.t("calendar.hr")), 1),
                _createVNode("th", _hoisted_11, _toDisplayString($setup.t("calendar.tags")), 1),
                _createVNode("th", _hoisted_12, _toDisplayString($setup.t("calendar.operation")), 1)
              ])
            ]),
            _createVNode("tbody", null, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.tableData.data, (item, index) => {
                return (_openBlock(), _createBlock("tr", {
                  key: item.diagnosis_id
                }, [
                  _createVNode("th", null, _toDisplayString(index + 1), 1),
                  _createVNode("td", _hoisted_13, _toDisplayString(item.diagnosis_id), 1),
                  _createVNode("td", null, _toDisplayString(item.create_date), 1),
                  _createVNode("td", _hoisted_14, _toDisplayString(item.hr_last), 1),
                  _createVNode("td", _hoisted_15, _toDisplayString(item.prediction_result), 1),
                  _createVNode("td", null, [
                    (_ctx.dbNum === 'v1')
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: {
                      name: 'diagnosesChart',
                      params: { diagnosesid: item.diagnosis_id }
                    },
                          target: "_blank",
                          class: "btn btn-outline-primary btn-sm text-nowrap"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.t("calendar.show")), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      : (_openBlock(), _createBlock(_component_router_link, {
                          key: 1,
                          to: {
                      name: 'diagnosesChart',
                      params: { diagnosesid: item.diagnosis_id }
                    },
                          target: "_blank",
                          class: "btn btn-outline-primary btn-sm text-nowrap"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.t("calendar.show")), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                  ])
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}